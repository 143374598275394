const url = "https://stock.tt9898.org/api/validation";


export const Login = (e, id, password, cleanFunction, memoId, memoPassword) => {

    e.preventDefault();
    console.log("submit");
    const loginInfo = getLoginForm(id, password);
    fetchStockAPI(url, loginInfo.id, loginInfo.password).then(isFetchOK => {
        const serverId = localStorage.getItem("userId");
        const token = localStorage.getItem("token");
        if(isFetchOK){
            memoId? localStorage.setItem("stock-id", id) : localStorage.setItem("stock-id", "");
            memoPassword? localStorage.setItem("stock-password", password) : localStorage.setItem("stock-password", "");

            localStorage.setItem( "memo-id", memoId );
            localStorage.setItem( "memo-password", memoPassword );

            goToStockPage(serverId, token);
            // cleanFunction();
        } else {
            alert("帳號或密碼錯誤")
        }
        
    }).catch(error => {
        console.error('Error during login:', error);
    });
}

const getLoginForm = (id, password) => {
    if(!id){id = ""};
    if(!password){password = ""};

    const loginInfo = {
        id: id,
        password: password,
    }

    return loginInfo;
}

//zwtest601
const fetchStockAPI = (url, id, password) => {

    const formData = new FormData();
    formData.append("LoginAccount", id);
    formData.append("LoginPassword", password);
    return fetch(url, {
        method: "POST",
        body: formData,
    }).then(response => {
        const apiResponse = response.json();
        return apiResponse;
    }).then(response => {
        if(response.ErrorMsg !== ""){
            alert(response.ErrorMsg);
            localStorage.removeItem("userId");
            localStorage.removeItem("token");
            return false;
        }else{
            // alert("登入成功");
            localStorage.setItem("userId", response.UserId);
            localStorage.setItem("token", response.Token);
            return true;
        }        
    }).catch(error => {
        console.log(error);
        return false;
    })
}

const goToStockPage = (id, token) => {
    const isMobile = checkIsMobile();

    // alert(isMobile);

    let confirmedURL;
    let layout;

    if( isMobile ) {
        confirmedURL = `https://stock.tt9898.org/mobi/?UserID=${id}&UserToken=${token}&ReturnURL=https://tt9898.net&lang=zh_TW`;
        layout = "手機版";
    } else {
        confirmedURL = `https://stock.tt9898.org/market.php?UserID=${id}&UserToken=${token}&ReturnURL=https://tt9898.net&lang=zh_TW`;
        layout = "電腦版";
    }

    const isNewWindow = window.confirm(`登入成功，立即前往 "${layout}" 股票平台?`);

    if(isNewWindow) {
        window.location.assign(confirmedURL);
    }

}

// export const getConfirmedURL = (id, token) => {

//     const confirmedURL = `https://tt9898.org:1443/market.php?UserID=${id}&UserToken=${token}&ReturnURL=https://tt9898.net&lang=zh_TW`;
//     return confirmedURL;
// }

const checkIsMobile = () => {
    let isMobile;

    const userAgent = navigator.userAgent;

    const width = window.innerWidth;
    const height = window.innerHeight;


    
    const isMobileAgent = /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/.test(userAgent);

    let isMobileSize;
    if( width <= 800 && height <= 1000) {
        isMobileSize = true;
    } else {
        isMobileSize = false;
    }



    if( isMobileAgent || isMobileSize ) {
        isMobile = true;
    } else {
        isMobile = false;
    }

    return isMobile;
}