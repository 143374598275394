import Header from "./components/Header";
import Footer from "./components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { LocationContext } from "../App";
import { useContext, useEffect, useRef, useState } from "react";
import "./NotAvailable.css";

const NotAvailable = ({ page }) => {
    const {languageBook, urlData} = useContext(LocationContext);

    const pageName = getPageName( languageBook, page);

    const navigate = useNavigate();

    const countDown = 8000;
    const [timer, setTimer] = useState(countDown);
    const timerRef = useRef(null);

    useEffect(() => {
        if( timer <= 0) {
            navigate(`${urlData.location}/`);
            return;
        }

        const timerRefCurrent = timerRef.current;
        if( timerRefCurrent ) {
            timerRefCurrent.style.setProperty("--timer-percent", `${ timer / countDown }`);
        }

        setTimeout(() => {
            setTimer(timer - 50);
        }, 50);
    }, [timer, navigate, urlData])

    return(
        <>
            <Header />
                <main id="not-avai-main">
                    <section id="not-available-sec" className="reading-sec">
                        <h1>{`${pageName} ${languageBook.notAvaiTitle}`}</h1>
                        <div>
                            <p>{languageBook.notAvaiDesc}</p>
                            <p>{languageBook.notAvaiContactUs}</p>
                        </div>
                        <div>
                            <p>不好意思，您所在的地區尚未開放本服務</p>
                            <p>請與我們聯絡以獲取更多資訊</p>
                        </div>
                        
                        <div id="redirect-links">
                            <Link to={`${urlData.location}/`} className="button" id="back-to-home">{languageBook.backToHome}</Link>
                            <Link to={`${urlData.location}/#line-sec`} className="button">{languageBook.contactUs}</Link>
                        </div>
                        <div id="timer-wrap">
                            <p>{`${languageBook.redirectingMessage1}${Math.ceil(timer/1000)}${languageBook.redirectingMessage2}`}</p>
                            <div id="timer" ref={timerRef}></div>
                        </div>
                        
                    </section>
                </main>
                
            <Footer />
        </>
    )
}

export default NotAvailable;

const getPageName = (languageBook, page) => {
    let pageName;

    if( page === "Stock" ) {
        pageName = languageBook.stockPlatform;
    } else if( page === "ApplyNow") {
        pageName = languageBook.applyNow;
    } else if( page === "AntiScam") {
        pageName = languageBook.antiScheme;
    } else {
        pageName = "";
    }

    return pageName;
}