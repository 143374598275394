import { useEffect } from "react";
import EarthToon from "../../page-components/components/EarthToon";
import styles from "./EarthWebGL.module.css";

const EarthWebGL = () => {

    useEffect(() => {
        const body = document.body;
        const root = document.querySelector("#root");
        const wrapper = document.querySelector("#earth-dot-wrapper");
        

        if( body ) {
            body.classList.add( styles["webgl-body"]);
        }

        if( root ) {
            root.classList.add( styles["webgl-root"]);
        }

        if( wrapper ) {
            wrapper.classList.add( styles["webgl-earth-dot-wrapper"]);
        }

        const apiMessageEn = `About the 3D Earth:\n    The 3D interactive globe is developed by LLstudio. If you also need WebGL 3D technology for your website, please contact us using the information below.\n-----------`
        const apiMessageZh = `\n關於3D地球:\n    3D可交互地球由 LLstudio 開發，如果您也需要網頁 WebGL 3D 技術，請透過下方資訊與我們聯絡。\n-----------`
        const apiMessageContact = `\n信箱|Email: kaihsin.lin.id@gmail.com\n網站|Website: https://ll-studio-design.com`;
        
        setInterval(() => {
            console.log(apiMessageEn +apiMessageZh + apiMessageContact);
        }, 20000);
        
    }, [])

    return(
        <EarthToon minWidth={100}/>    
    )
}

export default EarthWebGL;